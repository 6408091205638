import Plyr from "plyr";

let plyrOptions = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']
}

export const initPlayer = (id) => {
    let playerContainer = document.getElementById(id);

    if (playerContainer.classList.contains('plyr-init')) return;
    
    let player = playerContainer.querySelector('.player');

    player.classList.remove('hidden');
    
    if (playerContainer.dataset.mediaType == "podcast") {
        const sourceElement = document.createElement('source');
        sourceElement.setAttribute('src', player.dataset.src);
        player.appendChild(sourceElement);
    } else if (playerContainer.dataset.mediaType == 'video') {
        
    }

    playerContainer.classList.add('plyr-init');
    player = new Plyr(player, plyrOptions);
    player.on('ready', () => {
        player.elements.container.classList.add('plyr-ready');
        player.play();
    })
}