import Isotope from "isotope-layout"
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import Cookies from "js-cookie"
import Loadeer from "loadeer"
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const loadeer = new Loadeer();
loadeer.observe();

const swiperOptions = {
    default: {

    },
    projects: {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        slidesPerView: 'auto',
        grabCursor: true,
        breakpoints: {
            768: {
                slidesOffsetBefore: 40,
                slidesOffsetAfter: 40,
            }
        },
        cssMode: true,
    },
    logos: {
        modules: [Autoplay],
        breakpoints: {
            768: {
                slidesPerView: 6,
            }
        },
        slidesPerView: 3,
        loop: true,
        autoplay: {
            delay: 1500,
            pauseOnMouseEnter: false,
        },
        cssMode: true,
    }
}

const swipers = document.querySelectorAll('.swiper');
swipers.forEach(s => {
    const swiper = new Swiper(s, {...swiperOptions.default, ...swiperOptions[s.dataset.swiperType]});
});


import {subscribe} from './subscribe'
import {initPlayer} from './player'

window.subscribe = subscribe;
window.initPlayer = initPlayer;
window.Cookies = Cookies;

window.Alpine = Alpine
 
Alpine.plugin(intersect)
Alpine.start()


const init = () => {
    if (document.querySelector('.isotope')) {
        const iso = new Isotope('.isotope', {
            
        });
    }

    const preventLinks = document.querySelectorAll('[data-prevent]');
    preventLinks.forEach(pl => {
        pl.addEventListener('click', e => e.preventDefault);
    })

    async function getArticles(format = 'all', limit = 20) {
        const articles = await fetch(`/articles.json/format:${format}/limit:${limit}`);
        return articles.json();
    }
}


document.fonts.ready.then(() => {
    init();
})